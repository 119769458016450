<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-btn icon>
        <v-icon>mdi-cogs</v-icon>
      </v-btn>
      <v-toolbar-title>Automatisierung</v-toolbar-title>
      <v-spacer />
      <v-text-field
        class="mr-2"
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche"
        single-line
        hide-details
        clearable
        id="search"
      ></v-text-field>

      <template v-slot:extension>
        <v-tabs align-with-title>
          <v-tabs-slider color="error"></v-tabs-slider>
          <v-tab :to="{ name: 'AutomationJobs' }"> Übersicht </v-tab>
          <v-tab :to="{ name: 'AutomationChanges' }"> Änderungen </v-tab>
          <v-tab :to="{ name: 'AutomationTasks' }"> Aufgaben </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <router-view :search="search"></router-view>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Automation",

  data: () => ({
    search: null,
  }),
});
</script>
